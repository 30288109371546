.avatar_main {
  color: #dc2626 !important;
  background-color: #f5bebe80 !important;
}
.dialog_main .MuiPaper-root:first-of-type {
  /* overflow: visible !important; */
  max-width: max-content !important;
}
.dialog-overflow .MuiDialog-paper {
  overflow: visible;
}
