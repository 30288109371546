.panel > div {
  padding-top: 0px !important;
}
/* .panel > .MuiCardContent-root {
  height: 500px;
  overflow: scroll;
} */
.tab_main .MuiTabScrollButton-root {
  width: 20px !important;
}
.tab_main .tab_0,
.tab_main .tab_1,
.tab_main .tab_2,
.tab_main .tab_3,
.tab_main .tab_4,
.tab_main .tab_5 {
  color: red !important;
  border-radius: 0 !important;
  margin-bottom: 0px !important;
}
.indicator_main .MuiTabs-scroller .MuiTabs-indicator {
  background-color: #ff0000 !important;
  /* height: 0 !important; */
}
.indicator_secondary .MuiTabs-scroller .MuiTabs-indicator {
  background-color: #068e44 !important;
  /* height: 0 !important; */
}
/* Personal Info */
.personal_info_icon_button {
  width: 65px;
  color: #068e44;
  padding: 8px 12px;
  border-radius: 4px;
}
.personal_info_icon_button:hover {
  color: #068e44;
  background-color: none;
  /* background-color: #068e4430; */
}
.calendar_main input {
  padding: 10px 0px 10px 14px !important;
  font-size: 0.75rem !important;
}

.add_circle_button {
  margin-top: 0px !important;
  border-radius: 50%;
  height: 45px;
  min-width: 40px;
  padding: 6px 12px;
}
.add_circle_button .MuiButton-startIcon {
  margin: 0;
}
.add_circle_button svg {
  height: 24px;
  width: 22px;
}
