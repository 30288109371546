.table_body_main {
  overflow-x: auto;
}
.table_body_main::-webkit-scrollbar {
  height: 8px;
}
.table-body-container::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  /* border-radius: 100px; */
}

.table-body-container::-webkit-scrollbar-thumb {
  background-color: #00a854a5;
  /* border-radius: 100px; */
}

.table-body-container::-webkit-scrollbar-button {
  background-color: #e4e4e4; /* Set the background color for the scrollbar buttons */
}

.table_header::after {
  background-color: #f8f9fa !important;
}

.icon-only-button .MuiButton-startIcon {
  margin: 0px;
  display: flex;
}
